/* Header Two Search */
.header-search {
  position: relative;

  & input[type="text"] {
    font-size: 16px;
    line-height: 19px;

    width: 100%;
    height: 40px;
    margin: 0;
    padding-right: 10px;
    padding-left: 30px;

    color: $heading-color;
    border: none;
    background-color: transparent;

    @include placeholder {
      letter-spacing: 0.5px;
      color: #ABABAB;
    }
  }

  & .btn {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 30px;
    height: 40px;
    padding: 0;

    text-align: center;

    color: #ABABAB;

    & i {
      line-height: 40px;
    }

  }

  @media #{$large-mobile} {

    & .btn {
      position: static;
      color: $heading-color;
    }
    & input[type="text"] {
      display: none;
    }
    & input[type="text"].active {
      display: block;
      position: fixed;
      background: $dark;
      top: $header-mobile;
      left: 0px;
      color: white;
      @include placeholder {
        color: white;
      }
    }

  }
}

@media #{$large-mobile} {

  .moo-title-filter {
    font-size: 19px;

    &:after {
      top: 8px !important;
    }
  }
  .moo-filters .moo-filter ul {
    list-style: none;

    .moo-title-filter {
      &:after {
        top: 5px !important;
      }
    }
  }
}

@media #{$laptop-device} {


  & form {
    & input[type="text"] {
      width: 135px;
    }
  }
}
