// Font Family
$abeezee: "ABeeZee";
$futura: "Futura";
$marcellus: "Marcellus";
$modesty: "Modesty";
$body-font: $futura;
$heading-font: $marcellus;

// Icon Font
$fontAwesome5Brands: "Font Awesome 5 Brands";
$fontAwesome5Pro: "Font Awesome 5 Pro";


// Colors ---------------

$white: #FFFFFF;
$black: #000000;
$grey: #F8F9FD;
$grey-light: #E6E6E6;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// Body Color
$body-color: #696969;
$body-light: #7E7E7E;
$body-extra-light: #c5c5c5;

// Heading Color
$heading-color: #333333;


// Template Colors
$primary: #F8796C;
$primary2: #72A499;
$primary3: #BAA491;
$primary4: #fbfaf9;
$secondary: #6C757D;
$success: #4CAF50;
$danger: #F44336;
$warning: #FFC107;
$info: #17A2B8;
$light: #F8F9FA;
$dark: $heading-color;
$primary-light: lighten($primary, 27%);
$primary-dark: #ad554c;
$lightBlue: #c9dee4;

// All Template Color Map
$templateColors: ("primary": $primary,
        "primary2": $primary2,
        "primary3": $primary3,
        "secondary": $secondary,
        "success": $success,
        "danger": $danger,
        "warning": $warning,
        "info": $info,
        "light": $light,
        "dark": $dark,
        "black": $black,
        "grey": $grey,
        "body": $body-color,
        "body-light": $body-light,
        "heading": $heading-color,
);

// Default Transition
$transition: all 0.5s ease 0s;

// Socail Color
$android: #7AC157;
$apple: #B8B8B8;
$behance: #1869FF;
$codepen: $black;
$dribbble: #EA4C8A;
$dropbox: #007EE5;
$evernote: #78D525;
$facebook: #4867AA;
$github: #313131;
$google-drive: #1DA462;
$google-earth: #4285F4;
$google-glass: #EA4335;
$google-maps: #5083C3;
$google-play: #01B9FD;
$google-plus: #DD5144;
$google: #4285F4;
$instagram: #B23A94;
$css3: #0277BD;
$html5: #E44D26;
$javascript: #F9DC3D;
$python: #0C9DBF;
$lastfm: #E31B23;
$linkedin: #007BB6;
$paypal: #002F86;
$pinterest: #BD081B;
$pocket: #EF3E56;
$polymer: #F87292;
$rss: #F99C3A;
$share: #2C9CFF;
$stackoverflow: #F38024;
$steam: #15497B;
$twitter: #1DA1F2;
$vk: #5181B8;
$wikipedia: #E9E9E9;
$windows: #0078D6;
$s500px: $black;
$s8tracks: #122D4B;
$amazon: #F79B34;
$blogger: #F06A35;
$delicious: #0000FE;
$disqus: #2E9EFE;
$flattr: #7AB831;
$flickr: #FE0084;
$odnoklassniki: #F58220;
$outlook: #0072C6;
$playstation: #07418E;
$reddit: #FF4500;
$skype: #00A9F0;
$slideshare: #0077B5;
$soundcloud: #FE4900;
$tumblr: #36465D;
$twitch: #6441A4;
$vimeo: #1AB7EA;
$whatsapp: #189D0E;
$xbox: #107C0F;
$yahoo: #4101AF;
$youtube: #FE0000;

// Responsive Variables
$extraBig-device: "only screen and (min-width: 1500px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1499px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
$header-mobile: 60px;
