/* Header Language & Currency */
.header-lan-curr {
    font-size: 16px;
    font-weight: 400;

    display: flex;

    margin: 0;
    padding-left: 0;

    list-style: none;

    & > li {
        position: relative;

        margin-right: 16px;
        padding-right: 15px;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;

            width: 1px;
            height: 15px;

            content: "";
            transform: translateY(-50%);

            background-color: #D8D8D8;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;

            &::after {
                display: none;
            }
        }

        & > a {
            line-height: 1;

            padding: 5px 0;

            color: $body-light;

            &::after {
                font-family: $fontAwesome5Pro;
                font-size: 12px;

                margin-left: 5px;

                content: "\f107";

                opacity: 0.4;
            }
        }

        & > .curr-lan-sub-menu {
            position: absolute;
            z-index: 1;
            top: 150%;
            left: -15px;

            display: block;
            visibility: hidden;

            min-width: 120px;
            margin: 0;
            padding: 10px 15px;

            list-style: none;

            transition: $transition;
            letter-spacing: 0;

            opacity: 0;
            background-color: $white;
            box-shadow: -1px 10px 80px -15px rgba($black, 0.3);

            & li {
                line-height: 1.75;

                margin: 0;
                padding: 0;

                &::after {
                    display: none;
                }

                & + li {
                    margin-top: 5px;
                }

                & a {
                    display: block;

                    padding: 0;

                    color: $body-light;

                    &:hover {
                        padding-left: 5px;
                    }
                }
            }
        }

        &:hover {
            & > a {
                color: $heading-color;
            }

            & > .curr-lan-sub-menu {
                top: 100%;

                visibility: visible;

                opacity: 1;
            }
        }
    }
}