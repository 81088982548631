/* 09.05. Product Carousel CSS */

/* Products carousel */
.product-carousel {
  h2 {
    font-size: 40px;
    color: $dark;
    line-height: 56px;
    text-align: center;
    font-family: "Futura", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;

    &:before {
      content: url(../images/title/title-decor-left.png);
      padding-left: 10px;
    }

    &:after {
      content: url(../images/title/title-decor-right.png);
      padding-left: 10px;
    }
  }

  .slick-dots {
    display: none;
  }
}
