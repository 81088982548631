/* Sale Banner Style Generic CSS */
.sale-banner {
  & .sub-title {
    @include subtitles();
  }
}

.banner-8 .product {
  margin-bottom: 15px !important;
}

.banner-11 {
  .title {
    color: $primary3;
  }
}

#home-banners {

  @media #{$extraBig-device, $laptop-device} {
    .container {
      margin: auto;
      width: 90%;
      max-width: 90%;
    }
  }
}

#offer_of_day {
  .container {
    position: relative;

    .home5-slide-collection {
      text-transform: uppercase;
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
