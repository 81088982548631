
.modal-backdrop.fade.in {
  display: none;
}

.modal-header {
  &, .modal-title {
    font-weight: normal;
    font-size: 1.25rem;
  }

  .close {
    position: absolute;
    right: 1rem;
  }
}
