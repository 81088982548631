/* Breadcrumb CSS */
.breadcrumb-wrapper {
  margin: auto;
  display: none;

  .breadcrumb {
    display: block;
    justify-content: center;
    padding: 0.75rem 0 !important;

    li {
      display: inline-block;

      a::after {
        font-weight: 300;
        content: "/";
        display: inline-block;
        margin: 0 10px;
        font-size: 1rem;
        position: relative;
      }
    }

    li:last-of-type {
      a::after {
        display: none
      }
    }
  }

  &:not(:empty) {
    border: none;
    display: block;
  }
}
