/*----------------------------------------*/
/*  13. Instagram CSS
/*----------------------------------------*/

/* Instagram Carousel */
.instagram-carousel {
    & .slick-list {
        margin-right: -7.5px !important;
        margin-left: -7.5px !important;

        & .slick-slide {
            display: flex;

            padding-right: 7.5px;
            padding-left: 7.5px;
        }
    }
    @media #{$large-mobile} {
        overflow: hidden;
        & .slick-slider {
            margin-right: -10px;
            margin-left: -10px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

/* Instagram Grid */
.instagram-feed-grid {
    display: flex;

    & .instagram_gallery {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        & .instagram-image {
            display: block;
            flex: 1 0 16.6666%;

            max-width: 16.6666%;
        }
    }

    @media #{$desktop-device} {
        & .instagram_gallery {
            & .instagram-image {
                flex: 1 0 25%;

                max-width: 25%;
            }
        }
    }

    @media #{$tablet-device} {
        & .instagram_gallery {
            & .instagram-image {
                flex: 1 0 33.3333%;

                max-width: 33.3333%;
            }
        }
    }

    @media #{$large-mobile} {
        & .instagram_gallery {
            & .instagram-image {
                flex: 1 0 50%;

                max-width: 50%;
            }
        }
    }
}