/* 08.01. Sale Banner CSS */

@import "sale/banners";
@import "sale/banner1";
@import "sale/banner2";
@import "sale/banner3";
@import "sale/banner4";
@import "sale/banner5";
@import "sale/banner6";
@import "sale/banner7";
@import "sale/banner8";
@import "sale/banner9";
@import "sale/banner10";
@import "sale/banner11";
