/* Header Logo */
.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  @media #{$large-mobile} {
    padding-left: 15px;
  }

  & a {
    display: flex;
    align-self: flex-start;

    img {
      width: 100%;
      max-width: 100px;
      height: auto;
      align-self: flex-start;
    }
  }
}
