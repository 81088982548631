/*-----------------------------------------------------------------------------------

    Template Name: Waizabú – Handmade Shop eCommerce HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Template Spacing CSS
    03. Plugin Custom CSS (Custom style to override plugin default style)
        03.01. Image Zoom
        03.02. Ion Range Slider
        03.03. Parfect Scrollbar
        03.04. Photo Swipe
        03.05. Product 360
        03.06. Select 2
        03.07. Slick Slider
        03.08. Swiper Slider
    04. Elements CSS
        04.01 Accordion CSS
        04.02 Blockquote CSS
        04.03 Brand CSS
        04.04 Button CSS
        04.05 Form CSS
        04.06 Icon Box CSS
        04.07 Tooltip CSS
    05. Header CSS
        05.01 Topbar CSS
        05.02 Main Header CSS
    06. Slider CSS
        06.01. Home One Slider
        06.02. Home Two Slider
        06.03. Home Three Slider
        06.04. Home Four Slider
        06.05. Home Five Slider
        06.06. Home Six Slider
        06.07. Home Seven Slider
        06.08. Home Eight Slider
        06.09. Home Twelve Slider
    07. Titles CSS (Section, Block & Page Title/Page Header)
    08. Banner CSS
        08.01. Sale Banner CSS
        08.02. Category Banner CSS
        08.03. Parallax Banner CSS
        08.04. Other Banner CSS
    09. Product CSS
        09.01. Product One, Two & List Style CSS
        09.02. Deal Product CSS
        09.03. Single Product CSS
        09.04. Quick View CSS
        09.05. Related PRoduct CSS
    10. Blog CSS
        10.01. Blog Style CSS
        10.02. Single Blog CSS
    11. Team CSS
    12. Testimonial CSS
    13. Instagram CSS
    14. Countdown CSS
    15. Portfolio CSS
    16. Widget CSS
    17. Footer CSS
    18. Other Pages CSS
        18.01. Shop Page CSS
            18.01.01. Product Toolbar CSS
            18.01.02. Product Filter CSS
            18.01.03. Cart & Wishlist CSS
            18.01.04. Checkout CSS
            18.01.05. Order Tracking CSS
            18.01.06. Login & Register CSS
            18.01.07. Lost Password CSS
            18.01.08. My Account CSS
        18.02. SAC CSS
        18.03. 404 Page CSS
        18.04. Coming Soon Page CSS

-----------------------------------------------------------------------------------*/
@import "variabls";
@import "mixins";
@import "common";
@import "spacing";
@import "plugins";
@import "elements";
@import "header";
@import "slider";
@import "titles";
@import "banner";
@import "product";
@import "blog";
@import "team";
@import "testimonials";
@import "instagram";
@import "countdown";
@import "portfolio";
@import "widget";
@import "footer";
@import "pages";

