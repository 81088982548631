#login-widget-form {
  .btn-xenon {
    width: auto !important;
  }
}

.header-login {
  .btn {
    border: none !important;
  }

  .dropdown-toggle {
    margin-top: 15px;
    @media #{$large-mobile} {
      display: none;
    }
  }
}
