/* 18.01.02. Product Filter CSS */
.product-filter {
  display: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Filter Widget Title */
.product-filter-widget-title, .facets-group {
  font-family: $marcellus;
  margin-bottom: 0.5rem !important;
}


/* Filter Widget */
.product-filter-widget, .facets-group-container {
  max-height: 175px;
  padding-right: 15px;
  padding-left: 15px;

  &.sub-categories {
    position: relative;
    padding-right: 0px;
    padding-left: 0px;

    ul.nav.nav-pills {
      display: block;

      li {
        float: none !important;
      }
    }
  }

  input.filter_color {
    &:checked + label:after {
      @include color-circle-check();
      top: 15px;
      left: 13px;
    }

    & + label:before {
      @include color-circle();
      top: 3px;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      position: relative;
      font-weight: bold;

      &:before {
        @include filter-check();
      }
    }
  }

  .form-check {
    padding-left: 0px;
  }
}

