/* 18.02. Customer Service Page CSS */

/* Customer Service index*/
.sac {
  max-width: calc(100% + 30px);

  .item {
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.175;
      display: block;
      margin-bottom: 25px;
      color: $dark;
      padding: 15px;
    }

    .content, .actions {
      padding: 15px;
    }

  }
}
