.nav {
  padding-left: 0;
  margin-bottom: 0px;
  list-style: none;

  &.nav-stacked > li {
    float: none;
  }

  &.nav-pills > li {
    float: left;
  }

  & > li {
    position: relative;
    display: block;
    width: 100%;

    & > a {
      position: relative;
      display: block;
      padding: 10px 15px;
      color: $dark;
    }

    &.active > a, & > a:hover, & > a:focus {
      text-decoration: none;
      background-color: $primary;
      color: $white;
    }
  }
}
