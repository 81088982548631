/* Sale Banner Style Two CSS */
.sale-banner2 {
  & .inner {
    &:hover {
      .add-to-wishlist {
        z-index: 9;
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
      }

      .product2-buttons {
        visibility: visible;
        opacity: 1;
      }
    }

    & .image {
      position: relative;
      display: block;
      overflow: hidden;

      &::before {
        position: absolute;
        z-index: 2;
        top: 15px;
        left: 15px;

        width: calc(100% - 30px);
        height: calc(100% - 30px);

        content: "";

        border: 1px solid $white;
      }

      & img {
        width: 100%;
      }

      &:hover {
        & img {
          transform: scale(1.1);
        }
      }
    }

    & .content {
      h3 {
        position: absolute;
        @media #{$tablet-device,
    $large-mobile
    } {
          position: static;

        }
        bottom: 0;
        width: 100%;
        left: 0;
        font-size: 1.25rem;
      }

      & .sale-percent {
        font-size: 40px;
        font-weight: 400;
        line-height: 1.2;

        margin-bottom: 3px;
      }

      & .text {
        font-family: $abeezee;
        font-size: 16px;
        font-weight: 400;

        letter-spacing: 3px;
        text-transform: uppercase;

        color: $heading-color;
      }

      & .btn {
        background-color: $primary-light;

        &:hover {
          background-color: $dark;
        }
      }
    }
  }

  @media #{$small-mobile} {
    & .inner {
      & .image {
        margin-bottom: 20px;
      }
    }
  }
}
