/* 05.02 Main Header CSS */
@media #{$large-mobile} {
  body {
    margin-top: $header-mobile;
  }
  header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .header-section {
      .container {
        max-width: 100%;
        padding: 0 !important;

        @media #{$large-mobile} {
          & > .row {
            margin: 0px !important;
            display: block;
          }
          .col-auto {
            padding: 0;
            float: right !important;
            width: auto;
            padding-top: 10px;
            padding-right: 70px;
            max-width: 120px;
            @media #{$small-mobile} {
              max-width: 85px;
              padding-right: 50px;
            }
            .header-search {
              margin-left: 25px;
              @media #{$small-mobile} {
                margin-left: 5px;
              }
            }
          }
          .col-auto:first-of-type {
            max-width: calc(100% - 260px);
            float: left !important;
            padding: 0 !important;
            //display:none;
          }
          .col-auto:last-of-type {
            padding-right: 0px;
            width: auto;
          }
        }
      }
    }

  }
}

.header-section {
  position: relative;
  z-index: 98;

  & .container,
  & .container-fluid {
    position: relative;
  }

  & .row {
    & > [class*="col"] {
      position: static;
    }
  }

  &.header-menu-center {
    & .row {
      & > [class*="col"] {
        &:first-child,
        &:last-child {
          flex: 1 0 210px;

          max-width: 210px;
        }
      }
    }
  }

  &.header-transparent {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    background-color: transparent !important;
  }
}

/* Sticky Header */
.sticky-header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  width: 100%;

  transition: $transition;
  transform: translateY(-100%);

  border-bottom: 1px solid rgba($black, 0.075);

  & .container,
  & .container-fluid {
    position: relative;
  }

  & .row {
    & > [class*="col"] {
      position: static;
    }
  }

  &.header-menu-center {
    & .row {
      @media only screen and (min-width: 1200px) {
        & > [class*="col"] {
          &:first-child,
          &:last-child {
            flex: 1 0 210px;

            max-width: 210px;
          }
        }
      }
    }
  }

  &.is-sticky {
    transform: translateY(0);
  }
}

/* Mobile Header */
.mobile-header {
}


body.offside-header-left {
  margin-left: 400px;

  @media #{$laptop-device} {
    margin-left: 300px;
  }

  @media #{$desktop-device,
    $tablet-device,
    $large-mobile
    } {
    margin-left: 0;
  }
}

/* Header Call */
.header-call {
  position: absolute;
  top: 0;
  right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 100%;

  border-right: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;

  & p {
    font-size: 16px;
    font-weight: 600;

    & i {
      margin-right: 10px;
    }
  }
}

.header-login {
  min-width: 20px;

  .login-top {
    padding: 0px;
    width: 0;
    height: 0;
    visibility: hidden;

    i {
      visibility: visible;
      padding: 5px 0px;
    }
  }
}

.header-phone {

  min-width: 20px;
}
