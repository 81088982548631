/* Category Banner Style Five CSS */
.category-banner5 {
    position: relative;

    display: block;
    float: left;

    width: 100%;

    & .inner {
        position: relative;

        display: block;

        & .image {
            position: relative;

            display: block;

            margin-bottom: 40px;

            & img {
                display: block;

                width: 100%;
                max-width: 264px;
                margin: auto;

                border-radius: 50%;
            }
        }

        & .content {
            margin: 0;
            padding: 0;

            text-align: center;

            & .title {
                font-size: 24px;
                font-weight: 400;
                line-height: 1.125;

                margin: 0 0 15px;
            }

            & .number {
                font-size: 16px;
                font-weight: 400;

                display: block;

                transition: $transition;

                color: #999999;
            }
        }
    }

    &:hover {
        & .inner {
            & .image {
                & img {
                    transform: scale(1.1);
                }
            }

            & .content {
                & .number {
                    color: $heading-color;
                }
            }
        }
    }
}