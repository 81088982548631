/*----------------------------------------*/
/*  12. Testimonial CSS
/*----------------------------------------*/
.testimonial-carousel {
    @media #{$large-mobile} {
        &.slick-slider {
            margin-right: -10px;
            margin-left: -10px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

/* Testimonial Style One */
.testimonial {
    padding-right: 20px;

    & p {
        font-size: 18px;
        font-weight: 400;
        line-height: 2;

        margin-bottom: 48px;

        opacity: 0.8;
        color: #454545;
    }

    & .author {
        display: flex;
        align-items: center;

        & img {
            width: 80px;
            height: 80px;
            margin-right: 20px;

            border-radius: 50%;

            object-fit: cover;
        }

        & .content {
            text-align: left;

            & .name {
                font-family: $futura;
                font-size: 18px;
                font-weight: 600;
                line-height: 1;

                margin-bottom: 18px;

                color: $heading-color;
            }

            & .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 1;

                display: block;

                letter-spacing: 1px;
                text-transform: uppercase;

                color: $body-light;
            }
        }
    }

    @media #{$large-mobile} {
        text-align: center;

        & p {
            line-height: 1.5;
        }

        & .author {
            justify-content: center;
        }
    }
}

/* Testimonial Style Two */
.testimonial2 {
    text-align: center;

    & p {
        font-family: $marcellus;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.75;

        max-width: 800px;
        margin-right: auto;
        margin-bottom: 40px;
        margin-left: auto;

        opacity: 0.8;
        color: #454545;
    }

    & .author {
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            width: 100px;
            height: 100px;
            margin-right: 30px;

            border-radius: 50%;

            object-fit: cover;
        }

        & .content {
            text-align: left;

            & .name {
                font-family: $futura;
                font-size: 18px;
                font-weight: 600;
                line-height: 1;

                margin-bottom: 18px;

                color: $heading-color;
            }

            & .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 1;

                display: block;

                letter-spacing: 1px;
                text-transform: uppercase;

                color: $body-light;
            }
        }
    }

    @media #{$large-mobile} {
        & p {
            font-size: 20px;
        }

        & .author {
            & img {
                width: 80px;
                height: 80px;
            }
        }
    }

    @media #{$small-mobile} {
        & p {
            font-size: 18px;
        }
    }
}