/* 18.01.06. Login & Register CSS */

/* Login & Register Wrapper */
.user-login-register {
    font-weight: 400;

    padding: 60px;

    @media #{$small-mobile} {
        padding: 40px;
    }

    @media #{$extra-small-mobile} {
        padding: 30px;
    }
}

/* Login & Register Title */
.login-register-title {
    margin-bottom: 40px;

    text-align: center;
}

/* Login & Register Form */
.login-register-form {
    & form {
        & input {
            font-weight: 400;

@include placeholder {
                font-weight: 400;
            }
        }

        & p,
        & a {
            font-size: 16px;
        }

        & a {
            color: $heading-color;
        }
    }
}