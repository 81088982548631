/*----------------------------------------*/
/*  03. Plugin Custom CSS
/*----------------------------------------*/

@import "plugins/imageZoom";
@import "plugins/irs";
@import "plugins/parfect-scroll";
@import "plugins/photoSwipe";
@import "plugins/product-360";
@import "plugins/select2";
@import "plugins/slick";
@import "plugins/swiper";