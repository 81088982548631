.panel.panel-default {
  .panel-heading, .panel-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.175;
    display: block;
    margin-bottom: 25px;
    color: $dark;
  }
}

.well {
  padding: 15px;
}
