/* Page Title Section */
.page-title-section {
    padding: 130px 0;

    @media #{$desktop-device,
    $tablet-device
    } {
        padding: 80px 0;
    }

    @media #{$large-mobile} {
        padding: 60px 0;
    }

    @media #{$extra-small-mobile} {
        padding: 50px 0;
    }
}

/* Page Title */
.page-title {
    & .title {
        font-size: 56px;
        font-weight: 400;

        margin-bottom: 30px;
    }

    & .breadcrumb {
        padding: 0;

        background-color: transparent;

        & .breadcrumb-item {
            font-size: 16px;
            font-weight: 400;

            color: #999999;

            & + .breadcrumb-item::before {
                content: ">";

                color: $heading-color;
            }

            & a {
                &:hover {
                    color: $heading-color;
                }
            }

            &.active {
                color: $heading-color;
            }
        }
    }

    @media #{$desktop-device} {
        & .title {
            font-size: 44px;

            margin-bottom: 20px;
        }
    }

    @media #{$tablet-device} {
        & .title {
            font-size: 40px;

            margin-bottom: 20px;
        }
    }

    @media #{$large-mobile} {
        & .title {
            font-size: 30px;

            margin-bottom: 15px;
        }
    }

    @media #{$small-mobile} {
        & .title {
            font-size: 24px;
        }
    }

    @media #{$extra-small-mobile} {
        & .title {
            font-size: 20px;

            margin-bottom: 10px;
        }
    }
}