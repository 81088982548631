/* 06.05. Home Five Slider */
.home5-slider {
  position: relative;

  height: 720px;

  & .swiper-container {
    height: 100%;
  }


  & .swiper-pagination {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }

  @media #{$large-mobile} {
    height: 700px;

    & .swiper-pagination {
      bottom: 25px;
      left: 30px;
    }
  }
}

.home5-text {
  background: $primary4;
  padding: 2rem;

  .read-more, .read-less {
    display: none;
    cursor: pointer;
    color: $primary;
    font-weight: bold;
  }

  @media #{$large-mobile} {
    .read-more {
      display: inline;
    }
    .more-info {
      display: none;
    }
  }
}

.home5-ml-5 {
  margin-left: 3rem !important;
  @media #{$large-mobile} {
    margin-left: 0 !important;
  }
}

.banner-8, .banner-1, .home5-ml-5 {
  @media #{$large-mobile} {
    .home5-slide-collection {
      position: static !important;
      writing-mode: horizontal-tb !important;
      display: inline-block !important;
      width: 100% !important;
      transform: none !important;
    }
  }
}

/* Home Five Slide Item */
.home5-slide-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 150px;

  background-image: linear-gradient(to right, #F4F3EC 0%, #F4F3EC 60%, $white 60%, $white 100%);

  @media #{$laptop-device,
    $desktop-device
    } {
    padding: 0 100px;
  }

  @media #{$tablet-device,
    $large-mobile
    } {
    padding: 0 50px;

    background-color: #F4F3EC;
    background-image: none;
  }
}

/* Home Five Slide One Image */
.home5-slide1-image {
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;

  & img {
  }

  @media #{$large-mobile} {
    max-width: 300px;
    margin: 0 auto;
  }
}

/* Home Five Slide One Content */
.home5-slide1-content {
  text-align: center;

  & .sub-title {
    font-family: $modesty;
    font-size: 80px;
    font-weight: 400;
    line-height: 1;

    display: block;

    margin-bottom: 20px;

    color: #454545;

    &::before {
      padding-right: 20px;

      content: url("../images/title/title-decor-left.png");

      filter: grayscale(100);
    }

    &::after {
      padding-left: 20px;

      content: url("../images/title/title-decor-right.png");

      filter: grayscale(100);
    }
  }

  & .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;

    margin-bottom: 0;
  }

  @media #{$large-mobile} {
    & .sub-title {
      font-size: 50px;

      margin-bottom: 10px;
    }
  }

  @media #{$small-mobile} {
    & .sub-title {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  @media #{$extra-small-mobile} {
    & .sub-title {
      font-size: 40px;
    }
  }
}

/* Home Five Slide Two Image */
.home5-slide2-image {
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;

  & img {
  }

  @media #{$large-mobile} {
    max-width: 300px;
    margin: 0 auto;
  }
}

/* Home Five Slide Two Content */
.home5-slide2-content {
  text-align: center;

  & .sub-title {
    font-family: $modesty;
    font-size: 120px;
    font-weight: 400;
    line-height: 1;

    display: block;

    margin: 0;

    color: #454545;

    &::before {
      padding-right: 10px;

      content: url("../images/title/title-decor-left.png");

      filter: grayscale(100);
    }

    &::after {
      padding-left: 10px;

      content: url("../images/title/title-decor-right.png");

      filter: grayscale(100);
    }
  }

  & .title {
    font-size: 64px;
    font-weight: 400;
    line-height: 1.25;

    margin-bottom: 30px;
  }

  & .btn {
    color: $heading-color;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $heading-color;
    }
  }

  @media #{$laptop-device,
    $desktop-device
    } {
    & .sub-title {
      font-size: 90px;
    }

    & .title {
      font-size: 50px;
    }
  }

  @media #{$tablet-device} {
    & .sub-title {
      font-size: 50px;

      margin-bottom: 10px;
    }

    & .title {
      font-size: 34px;
    }

    & .btn {
      padding: 10px 30px;
    }
  }

  @media #{$large-mobile} {
    & .sub-title {
      font-size: 80px;

      margin-bottom: 10px;
    }

    & .title {
      font-size: 40px;
    }

    & .btn {
      padding: 10px 30px;
    }
  }

  @media #{$small-mobile} {
    & .sub-title {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  @media #{$extra-small-mobile} {
    & .sub-title {
      font-size: 40px;
    }

    & .title {
      font-size: 30px;

      margin-bottom: 20px;
    }

    & .btn {
      font-size: 14px;

      padding: 8px 20px;
    }
  }
}

/* Home Five Slide Three Image */
.home5-slide3-image {
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;

  & img {
  }

  @media #{$large-mobile} {
    max-width: 90%;
    margin: 0 auto;
  }
}

/* Home Five Slide Three Content */
.home5-slide3-content {
  text-align: center;

  & .sub-title {
    font-family: $modesty;
    font-size: 70px;
    font-weight: 400;
    line-height: 1;

    display: block;

    margin-bottom: 20px;

    color: #454545;
  }

  & .title {
    font-family: $futura;
    font-size: 60px;
    font-weight: 600;
    line-height: 1.25;

    margin-bottom: 0;

    letter-spacing: 14px;
    text-transform: uppercase;

    color: $primary3;
  }

  @media #{$desktop-device} {
    & .title {
      font-size: 50px;
    }
  }

  @media #{$tablet-device,
    $large-mobile
    } {
    & .sub-title {
      font-size: 55px;
    }

    & .title {
      font-size: 40px;
    }
  }

  @media #{$small-mobile} {
    & .sub-title {
      font-size: 45px;
    }

    & .title {
      font-size: 30px;
    }
  }

  @media #{$extra-small-mobile} {
    & .sub-title {
      font-size: 40px;
    }

    & .title {
      font-size: 24px;

      letter-spacing: 5px;
    }
  }
}

/* Home Five Slide Collection & Sale */
.home5-slide-collection,
.home5-slide-sale {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 0px;
  letter-spacing: 5px;
  color: $heading-color;
  writing-mode: vertical-rl;
  transform: translateY(-50%) rotate(180deg);
  z-index: 98;
  text-align: center;
  display: inline-block;


  @media #{$tablet-device,
    $large-mobile
    } {
    font-size: 14px;
  }

  @media #{$extra-small-mobile} {
    font-size: 12px;
  }
}

/* Home Five Slide Sale */
.home5-slide-sale {
  right: 20px;
  left: auto;
}

/* Home Five Slide Link */
.home5-slide-shop-link {
  position: absolute;
  right: 50px;
  bottom: 40px;

  &::before {
    position: absolute;
    top: 50%;
    right: calc(100% + 15px);

    width: 60px;
    height: 2px;

    content: "";
    transform: translateY(-50%);

    background-color: $heading-color;
  }

  & a {
    font-weight: 400;

    color: $heading-color;

    &:hover {
      color: $primary2;
    }
  }

  @media #{$laptop-device,
    $desktop-device
    } {
    right: 40px;
  }

  @media #{$tablet-device,
    $large-mobile
    } {
    right: 30px;
    bottom: 30px;
  }

  @media #{$extra-small-mobile} {
    right: 20px;
    bottom: 20px;

    &::before {
      display: none;
    }

    & a {
      font-size: 14px;
    }
  }
}

.section.text-justify {
  h2 {
    text-align: center;
    color: $dark;
    font-weight: bold;
  }
}


@keyframes homr5image {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
