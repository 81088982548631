/* 18.03. 404 Page CSS */

/* 404 Section */
.section-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;

  @media #{$tablet-device,
    $large-mobile
    } {
    min-height: calc(100vh - 70px);
  }
}

/* 404 Content */
.content-404 {
  .page-not-found {

    & .title {
      font-size: 100px;
      font-weight: 400;
      line-height: 1;
      margin: 0;
    }
  }

  & .sub-title {
    font-size: 64px;
    font-weight: 400;
    line-height: 1.2;

    margin-bottom: 30px;
  }

  & p {
    margin-bottom: 40px;
  }

  & .buttons {
    text-align: center;
  }

  @media #{$large-mobile} {

    & .title {
      font-size: 80px;
    }

    & .sub-title {
      font-size: 50px;
    }
  }

  @media #{$small-mobile} {
    & .title {
      font-size: 60px;
    }

    & .sub-title {
      font-size: 40px;
    }
  }
}
