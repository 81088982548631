/* 18.05. Resume order CSS */
.main-cart {
  .h3 {
    color: $dark;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.175;
    display: block;
    margin-bottom: 25px;
    font-family: "Marcellus", Arial, Helvetica, sans-serif;
  }

  a {
    font-weight: bold;
  }

  .legal {
    border: 1px solid $grey-light;
    padding: 10px;
    border-radius: 5px;

  }
}


@media(max-width: 768px) {

  .table.table-responsive {
    thead {
      display: none;
      width: 100%;
    }

    tbody {
      width: 100%;

      tr {
        border: 1px solid $grey-light;
      }

      td {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 25% !important;
        border: none !important;

        div {
          width: 100%;
          min-width: 100%;
          flex: 1;
        }
      }

      td:empty {
        display: none;
      }

      td:before {
        padding-top: 0.75rem;
        display: block;
        content: attr(data-th);
        font-weight: bold;
        text-align: left;
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 0.75rem;
        width: calc(25% - 10px);
        padding-right: 10px;
        white-space: nowrap;
      }

      .productName {
        font-size: 1rem;
      }

      .productDescription {
        border-top: 1px solid #ddd;
        margin-top: 0px;
        padding-top: 0px;

      }
    }
  }
}
