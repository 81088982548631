/*----------------------------------------*/
/*  06. Slider CSS
/*----------------------------------------*/

@import "slider/home1";
@import "slider/home2";
@import "slider/home3";
@import "slider/home4";
@import "slider/home5";
@import "slider/home6";
@import "slider/home7";
@import "slider/home8";
@import "slider/home12";