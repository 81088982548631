// Mixins
// --------------------------

@mixin title-modesty() {
  font-family: $modesty;
  text-align: center;
  font-size: 7rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
  color: $body-color;
  @media #{$desktop-device,
    $small-mobile} {
    font-size: 6rem;
  }

  @media #{$extra-small-mobile} {
    font-size: 4rem;
  }

}

@mixin title-futura() {
  font-family: $futura;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: 10px;
  text-transform: uppercase;
  @include subtitles();
}

@mixin color-circle() {
  font-weight: normal;
  border-radius: 50%;
  color: $grey-light;
  border: 1px solid $grey-light !important;
}

@mixin filter-check() {
  content: '\f00c';
  font-family: $fontAwesome5Pro;
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 14px;
}

@mixin color-circle-check() {
  @include filter-check();
  color: $grey-light;
  font-weight: normal;
  position: absolute;
}

@mixin subtitles() {
  font-size: 1.75rem;
  color: $black;
  @media #{$desktop-device,
    $small-mobile} {
    font-size: 1.5rem;
  }
}
