/* 18.01.01. Product Toolbar CSS */
.shop-toolbar {
  padding-top: 25px;
  padding-bottom: 25px;
  display: grid;
  align-items: center;
  grid-template-areas:
            "a b c"
            "d d d";
  @media #{$tablet-device, $large-mobile} {
    grid-template-areas:
            "a a"
            "b c"
            "d d";
    h1 {
      text-align: left;
    }
  }
  @media #{$small-mobile} {
    grid-template-areas:
            "a"
            "b"
            "c"
            "d";
    h1 {
      text-align: center;
    }
    .shop-toolbar-list {
      text-align: center !important;
    }
  }

  .breadcrumb-wrapper {
    grid-area: a;
    margin: 0px;
  }

  h1 {
    grid-area: b;
  }

  #facet-search-form {
    background-color: #f8f9fa;
    margin-bottom: 1rem;
    width: 100%;
    @media #{$large-mobile} {
      .product-filter-widget {
        margin-top: 30px;

      }
      .product-filter-widget:first-of-type {
        margin-top: 0px;
      }
    }
  }


  /* Shop Product Filter */
  .shop-product-filter {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -20px -10px;

    & button {
      font-size: 18px;
      line-height: 1;

      margin: 0 20px 10px;
      padding: 0;

      text-transform: uppercase;

      color: #ABABAB;
      border: none;
      background-color: transparent;

      &:hover,
      &.active {
        color: $heading-color;
      }
    }

    @media #{$desktop-device,
    $tablet-device,
    $large-mobile
    } {
      margin: 0 -15px -10px;

      & button {
        font-size: 16px;

        margin: 0 15px 10px;
      }
    }

    @media #{$small-mobile} {
      margin: 0 -10px -10px;

      & button {
        font-size: 14px;

        margin: 0 10px 10px;
      }
    }
  }

  /* Shop Toolbar Control */
  .shop-toolbar-list {
    grid-area: c;
    margin: 0 -15px;
    padding-left: 0;
    text-align: right;
    list-style: none;

    & > li {
      display: inline-block;
      margin: 0 15px;
    }

    @media #{$desktop-device,
    $tablet-device,
    $large-mobile
    } {
      justify-content: space-between;
    }
  }

  /* Product Sorting */
  .product-sorter {
    display: inline-block;
    position: relative;
    z-index: 9;
    text-align: left;

    height: auto;
    padding-left: 0;

    border: none;
    border-bottom: 2px solid #CCCCCC;
    border-radius: 0;

    button.dropdown-toggle {
      padding: 0px 20px 4px 0;
      border: none;
      background: transparent;
      position: relative;
      font-size: 16px;
      line-height: 28px;
      display: block;

      &::after {
        font-family: $fontAwesome5Pro;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 4px;
        bottom: auto;

        width: auto;
        height: auto;
        margin: 0;

        content: "\f0d7";
        transform: rotate(0) translateY(-50%);

        color: #ABABAB;
        border: none;
      }
    }


    & .dropdown-menu {
      margin-top: 10px;
      padding: 15px;
      border: none;
      transform: scale(1) translate(0, 0);
      border-radius: 0;
      box-shadow: 0 3px 5px rgba($black, 0.15);

      & li {
        font-size: 16px;
        font-weight: 400 !important;
        line-height: 1.25;
        min-height: auto;

        a {
          padding: 8px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;

          &.selected {
            color: $heading-color;
            background-color: transparent;
          }

          &:hover {
            transform: translateX(5px);
            background-color: transparent;
          }
        }
      }
    }
  }

  /* Product Column Toggle */
  .product-column-toggle {
    display: flex;

    margin: 0 -10px;

    & .toggle {
      margin: 0 10px;
      padding: 0;

      color: #ABABAB;
      border: none;
      background-color: transparent;

      & i {
        font-size: 14px;
      }

      &:hover,
      &.active {
        color: $heading-color;
      }
    }
  }

  /* Product Filter */
  .product-filter {
    grid-area: d;
  }

  /* Product Filter Toggle */
  .product-filter-toggle {
    font-size: 16px;
    font-weight: 400;
    line-height: 2;

    position: relative;

    display: flex;
    align-items: center;

    color: $body-color;

    &::before {
      font-family: $fontAwesome5Pro;
      font-size: 13px;
      line-height: 1;

      margin-right: 6px;
      margin-bottom: -3px;

      content: "\f0b0";
      transition: $transition;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;

      width: 0;
      height: 1px;

      content: "";
      transition: $transition;

      background-color: $heading-color;
    }

    &:hover {
      color: $heading-color;

      &::after {
        right: auto;
        left: 0;

        width: 100%;
      }
    }

    &.active {
      color: $heading-color;

      &::before {
        transform: rotate(180deg);
      }

      &::after {
        right: auto;
        left: 0;

        width: 100%;
      }
    }
  }
}

.shop-tags {
  ul.nav.nav-pills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      display: inline-block;
      width: auto;

      a {
        border: none;
        color: $gray-700;
        background-color: $lightBlue;
        border-radius: 52px;
        border-color: $lightBlue;
        font-size: 14px;
        padding: 8px 15px;
        font-family: $futura;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin: 5px;
      }
    }
  }
}
