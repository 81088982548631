/* Sale Banner Style Seven CSS */
.sale-banner7 {
  height: 100%;

  & .inner {
    position: relative;
    height: 100%;

    .home5-slide-collection {
      right: 20px;
      left: auto;
      transform: translateY(-50%) rotate(180deg);
    }

    & .image {
      overflow: hidden;

      width: 100%;
      height: 100%;

      & img {
        z-index: 1;

        display: block;

        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      &::before {
        position: absolute;
        z-index: 2;
        top: 15px;
        left: 15px;

        width: calc(100% - 30px);
        height: calc(100% - 30px);

        content: "";
        transition: $transition;

        opacity: 0;
        border: 1px solid $white;
      }
    }

    & .content {
      position: absolute;
      z-index: 9;
      top: 3%;
      left: 0;

      width: 100%;

      text-align: center;


      & .title {
        @include title-modesty();
      }

      & .sub-title {
        @include title-futura();
        color: $primary3 !important;
      }
    }
  }

  &:hover {
    & .inner {
      & .image {
        & img {
          transform: scale(1.1);
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }
}
