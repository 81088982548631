.select2-container--xlte {

  .select2-selection {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray-700;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-400 !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    .select2-selection__clear {
      position: absolute !important;
      right: 10px;
      top: 6px;
    }
  }
}
